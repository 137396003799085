import * as metarouter_script from "./metarouterscript";
import * as utilities from "../../utilities";
const remoteUtils = await import( 'AAPE_Sync/utilities' );

let appointmentMaker = function ( ddo ) {

	let eventIndex = ( typeof ( ddo.indexOfEventTrigger ) !== "undefined" ? ddo.indexOfEventTrigger : "" ),
		eventObj = ( eventIndex !== "" && ddo && ddo.event && ddo.event[ eventIndex ] ? ddo.event && ddo.event[ eventIndex ] : {} ),
		leadType = ( eventObj.form && eventObj.form.formName ? eventObj.form.formName : "" );

	_T.metarouter.track( 'HDDC Appointment Confirmation', {
		marketingCloudVisitorID: _aape.ECID,
		pageName: ( ddo.page.pageInfo.pageName ? ddo.page.pageInfo.pageName : '' ),
		pageType: ( ddo.page.pageInfo.pageType ? ddo.page.pageInfo.pageType : '' ),
		businessType: ( ddo.site.businessType ? ddo.site.businessType.toString().toLowerCase() : "b2c" ),
		primaryCategory: ( ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		subCategory2: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : '' ),
		pageFullCategory: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : ddo.page.category.subCategory1 ? ddo.page.category.subCategory1 : ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		lead_type: leadType,
		experienceType: ( remoteUtils.getDeviceType ? remoteUtils.getDeviceType() : '' ),
		mobileDevice: ( remoteUtils.getMobileDevice ? remoteUtils.getMobileDevice() : '' ),
		px: utilities.getPxScore()

	} );

}

let piqImpression = function ( eventData, pageInfo ) {

    let data = (eventData.data ? eventData.data : {}),
        pageName = (typeof (pageInfo) !== 'undefined' && pageInfo.pageName ? pageInfo.pageName : ''),
        pageType = (typeof (pageInfo) !== 'undefined' && pageInfo.pageType ? pageInfo.pageType : ''),
        campaignIdCheck = (data.campaignID && typeof (data.campaignID) === 'string' && data.campaignID.toLowerCase() !== "defaultstandardbanner");

	if ( window._T.metarouter && typeof window._T.metarouter.track === 'function' && campaignIdCheck ) {

		_T.metarouter.track( 'PIQ Impression', {
			marketingCloudVisitorID: _aape.ECID,
			pageName: pageName,
			campaignID: ( data.campaignID ? data.campaignID : '' ),
			flightID: ( data.flightID ? data.flightID : '' ),
			impressionID: ( data.impressionID ? data.impressionID : '' ),
			pageType: pageType,
			slotID: ( data.slotID ? data.slotID : '' ),
			experienceType: ( remoteUtils.getDeviceType ? remoteUtils.getDeviceType() : '' ),
			mobileDevice: ( remoteUtils.getMobileDevice ? remoteUtils.getMobileDevice() : '' ),
			px: utilities.getPxScore()
		} );

	}

}

let registrationEvent = function ( ddo ) {

	try {
		_T.metarouter.track( 'Signed Up', {
			marketingCloudVisitorID: _aape.ECID,
			pageName: ( ddo.page.pageInfo.pageName ? ddo.page.pageInfo.pageName : '' ),
			pageType: ( ddo.page.pageInfo.pageType ? ddo.page.pageInfo.pageType : '' ),
			businessType: ( ddo.site.businessType ? ddo.site.businessType.toString().toLowerCase() : "b2c" ),
			primaryCategory: ( ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
			subCategory2: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : '' ),
			pageFullCategory: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : ddo.page.category.subCategory1 ? ddo.page.category.subCategory1 : ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
			experienceType: ( remoteUtils.getDeviceType ? remoteUtils.getDeviceType() : '' ),
			mobileDevice: ( remoteUtils.getMobileDevice ? remoteUtils.getMobileDevice() : '' ),
			px: utilities.getPxScore()

		} );
	}
	catch ( err ) {
		console.warn( 'Caught Error - registrationEvent: ', err.message );
	}

}

let registrationProEvent = function ( ddo ) {

	try {
		_T.metarouter.track( 'Signed Up Pro', {
			marketingCloudVisitorID: _aape.ECID,
			pageName: ( ddo.page.pageInfo.pageName ? ddo.page.pageInfo.pageName : '' ),
			pageType: ( ddo.page.pageInfo.pageType ? ddo.page.pageInfo.pageType : '' ),
			businessType: ( ddo.site.businessType ? ddo.site.businessType.toString().toLowerCase() : "b2c" ),
			primaryCategory: ( ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
			subCategory2: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : '' ),
			pageFullCategory: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : ddo.page.category.subCategory1 ? ddo.page.category.subCategory1 : ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
			experienceType: ( remoteUtils.getDeviceType ? remoteUtils.getDeviceType() : '' ),
			mobileDevice: ( remoteUtils.getMobileDevice ? remoteUtils.getMobileDevice() : '' ),
			px: utilities.getPxScore()

		} );
	}
	catch ( err ) {
		console.warn( 'Caught Error - registrationProEvent: ', err.message );
	}

}

let run = function ( ddo, firePageEvent = true ) {
	let experienceTypeMr = ( remoteUtils.getDeviceType ? remoteUtils.getDeviceType() : '' ),
		mobileDeviceMr = ( remoteUtils.getMobileDevice ? remoteUtils.getMobileDevice() : '' ),
		defaultPageEvent = {
			pageName: ( ddo.page.pageInfo.pageName ? ddo.page.pageInfo.pageName : '' ),
			pageType: ( ddo.page.pageInfo.pageType ? ddo.page.pageInfo.pageType : '' ),
			primaryCategory: ( ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
			subCategory2: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : '' ),
			marketingCloudVisitorID: _aape.ECID,
			experienceType: experienceTypeMr,
			mobileDevice: mobileDeviceMr,
			px: utilities.getPxScore()
		};
	/* initialize core metarouter code */
	var a = window._T.metarouter = window._T.metarouter || [];
	if (!a.initialize) {
		if (a.invoked) {
			window.console && console.error && console.error("MetaRouter snippet included twice.");
		} else {
			a.invoked = !0;
			a.methods = [
				'trackSubmit',
				'trackClick',
				'trackLink',
				'trackForm',
				'pageview',
				'identify',
				'reset',
				'group',
				'track',
				'ready',
				'alias',
				'debug',
				'page',
				'once',
				'off',
				'on',
				'addSourceMiddleware',
				'addIntegrationMiddleware',
				'setAnonymousId',
				'addDestinationMiddleware',
			]
			a.factory = function (e) {
				return function () {
					var b = Array.prototype.slice.call(arguments);
					b.unshift(e);
					a.push(b);
					return a;
				};
			};
			for (var c = 0; c < a.methods.length; c++) {
				var d = a.methods[c];
				a[d] = a.factory(d);
			}
			metarouter_script.init();
			a.SNIPPET_VERSION = "4.13.1";
		}
	}
	if (firePageEvent) {
		a.page(defaultPageEvent)
	}
};

/*search method for plp and search pages. */
let search = function ( ddo ) {

	run( ddo );

	var getAstSku = function ( ddo ) {
		var contentSku = ( typeof ( ddo ) !== "undefined" && ddo.content && ddo.content.product ? ddo.content.product : [] ),
			astSkuList = [],
			skuLength = 3;
		if ( contentSku.length ) {
			skuLength = ( contentSku.length > 3 ? skuLength : contentSku.length );
			for ( var i = 0; i < skuLength; i++ ) {
				astSkuList.push( contentSku[ i ].productInfo && contentSku[ i ].productInfo.sku ? contentSku[ i ].productInfo.sku : '' );
			}
		}
		return astSkuList.join( "," );
	};

	_T.metarouter.track( 'Products Searched', {
		marketingCloudVisitorID: _aape.ECID,
		query: ( ddo.page.onsiteSearchInfo.searchTerm ? utilities.redactEmail(ddo.page.onsiteSearchInfo.searchTerm) : '' ),
		pageName: ( ddo.page.pageInfo.pageName ? ddo.page.pageInfo.pageName : '' ),
		pageType: ( ddo.page.pageInfo.pageType ? ddo.page.pageInfo.pageType : '' ),
		businessType: ( ddo.site.businessType ? ddo.site.businessType.toString().toLowerCase() : "b2c" ),
		pageFullCategory: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : ddo.page.category.subCategory1 ? ddo.page.category.subCategory1 : ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		primaryCategory: ( ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		subCategory2: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : '' ),
		pageUrl: document.location.href,
		products: ( ddo.content.product && ddo.content.product.length && ddo.content.product.length !== 0 ? getAstSku( ddo ) : '' ),
		headerCrumb: ( ddo.page.category.headerCrumb ? ddo.page.category.headerCrumb : '' ),
		experienceType: ( remoteUtils.getDeviceType ? remoteUtils.getDeviceType() : '' ),
		mobileDevice: ( remoteUtils.getMobileDevice ? remoteUtils.getMobileDevice() : '' ),
		px: utilities.getPxScore()

	} );

}
/*category method for cart view page */
let plp = function ( ddo ) {

	run( ddo );


	var getAstSku = function ( ddo ) {
		var contentSku = ( typeof ( ddo ) !== "undefined" && ddo.content && ddo.content.product ? ddo.content.product : [] ),
			astSkuList = [],
			skuLength = 3;
		if ( contentSku.length ) {
			skuLength = ( contentSku.length > 3 ? skuLength : contentSku.length );
			for ( var i = 0; i < skuLength; i++ ) {
				astSkuList.push( contentSku[ i ].productInfo && contentSku[ i ].productInfo.sku ? contentSku[ i ].productInfo.sku : '' );
			}
		}
		return astSkuList.join( "," );
	};

	_T.metarouter.track( 'Product List Viewed', {
		marketingCloudVisitorID: _aape.ECID,
		pageName: ( ddo.page.pageInfo.pageName ? ddo.page.pageInfo.pageName : '' ),
		pageType: ( ddo.page.pageInfo.pageType ? ddo.page.pageInfo.pageType : '' ),
		businessType: ( ddo.site.businessType ? ddo.site.businessType.toString().toLowerCase() : "b2c" ),
		pageFullCategory: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : ddo.page.category.subCategory1 ? ddo.page.category.subCategory1 : ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		products: ( ddo.content.product && ddo.content.product.length && ddo.content.product.length !== 0 ? getAstSku( ddo ) : '' ),
		primaryCategory: ( ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		subCategory2: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : '' ),
		experienceType: ( remoteUtils.getDeviceType ? remoteUtils.getDeviceType() : '' ),
		mobileDevice: ( remoteUtils.getMobileDevice ? remoteUtils.getMobileDevice() : '' ),
		px: utilities.getPxScore()
	} );

}

/*pip method for product information pages */
let pip = function ( ddo ) {

	run( ddo );

	_T.metarouter.track( 'Product Viewed', {
		marketingCloudVisitorID: _aape.ECID,
		pageName: ( ddo.page.pageInfo.pageName ? ddo.page.pageInfo.pageName : '' ),
		pageType: ( ddo.page.pageInfo.pageType ? ddo.page.pageInfo.pageType : '' ),
		businessType: ( ddo.site.businessType ? ddo.site.businessType.toString().toLowerCase() : "b2c" ),
		pageFullCategory: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : ddo.page.category.subCategory1 ? ddo.page.category.subCategory1 : ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		pageUrl: document.location.href,
		sku: ( ddo && ddo.product && ddo.product[ 0 ] && ddo.product[ 0 ].productInfo && ddo.product[ 0 ].productInfo.sku ? ddo.product[ 0 ].productInfo.sku : '' ),
		name: ( ddo && ddo.product && ddo.product[ 0 ] && ddo.product[ 0 ].productInfo && ddo.product[ 0 ].productInfo.productName ? ddo.product[ 0 ].productInfo.productName : '' ),
		brand: ( ddo && ddo.product && ddo.product[ 0 ] && ddo.product[ 0 ].productInfo && ddo.product[ 0 ].productInfo.manufacturer && ddo.product[ 0 ].productInfo.manufacturer !== 'unbranded' ? ddo.product[ 0 ].productInfo.manufacturer : '' ),
		category: ( ddo && ddo.product && ddo.product[ 0 ] && ddo.product[ 0 ].productInfo && ddo.product[ 0 ].productInfo.productType ? ddo.product[ 0 ].productInfo.productType : '' ),
		primaryCategory: ( ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		subCategory2: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : '' ),
		experienceType: ( remoteUtils.getDeviceType ? remoteUtils.getDeviceType() : '' ),
		mobileDevice: ( remoteUtils.getMobileDevice ? remoteUtils.getMobileDevice() : '' ),
		px: utilities.getPxScore()
	} );

}

/*add to cart method  */
let atc = function ( ddo ) {

	let eventIndex = ( typeof ( ddo.indexOfEventTrigger ) !== "undefined" ? ddo.indexOfEventTrigger : "" );

	_T.metarouter.track( 'Product Added', {
		marketingCloudVisitorID: _aape.ECID,
		pageName: ( ddo.page.pageInfo.pageName ? ddo.page.pageInfo.pageName : '' ),
		pageType: ( ddo.page.pageInfo.pageType ? ddo.page.pageInfo.pageType : '' ),
		businessType: ( ddo.site.businessType ? ddo.site.businessType.toString().toLowerCase() : "b2c" ),
		pageFullCategory: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : ddo.page.category.subCategory1 ? ddo.page.category.subCategory1 : ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		primaryCategory: ( ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		subCategory2: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : '' ),
		cartId: ( ddo.cart.cartID ? ddo.cart.cartID : '' ),
		cartValue: "",
		sku: ( ddo && ddo.event && ddo.event[ eventIndex ] && ddo.event[ eventIndex ].item && ddo.event[ eventIndex ].item[ 0 ] && ddo.event[ eventIndex ].item[ 0 ].productInfo && ddo.event[ eventIndex ].item[ 0 ].productInfo.sku ? ddo.event[ eventIndex ].item[ 0 ].productInfo.sku : '' ),
		name: ( ddo && ddo.product && ddo.product[ 0 ] && ddo.product[ 0 ].productInfo && ddo.product[ 0 ].productInfo.productName ? ddo.product[ 0 ].productInfo.productName : '' ),
		brand: ( ddo && ddo.product && ddo.product[ 0 ] && ddo.product[ 0 ].productInfo && ddo.product[ 0 ].productInfo.manufacturer && ddo.product[ 0 ].productInfo.manufacturer !== 'unbranded' ? ddo.product[ 0 ].productInfo.manufacturer : '' ),
		category: ( ddo && ddo.product && ddo.product[ 0 ] && ddo.product[ 0 ].productInfo && ddo.product[ 0 ].productInfo.productType ? ddo.product[ 0 ].productInfo.productType : '' ),
		price: ( ddo && ddo.event && ddo.event[ eventIndex ] && ddo.event[ eventIndex ].item && ddo.event[ eventIndex ].item[ 0 ] && ddo.event[ eventIndex ].item[ 0 ].price && ddo.event[ eventIndex ].item[ 0 ].price.basePrice ? ddo.event[ eventIndex ].item[ 0 ].price.basePrice : '0' ),
		quantity: ( ddo && ddo.event && ddo.event[ eventIndex ] && ddo.event[ eventIndex ].item && ddo.event[ eventIndex ].item[ 0 ] && ddo.event[ eventIndex ].item[ 0 ].quantity !== null && ddo.event[ eventIndex ].item[ 0 ].quantity !== undefined ? ddo.event[ eventIndex ].item[ 0 ].quantity : '' ),
		experienceType: ( remoteUtils.getDeviceType ? remoteUtils.getDeviceType() : '' ),
		mobileDevice: ( remoteUtils.getMobileDevice ? remoteUtils.getMobileDevice() : '' ),
		px: utilities.getPxScore()
	} );

}

/*cart method for cart view page */
let cart = function ( ddo ) {

	run( ddo );

	let astCartItem = ( ddo.cart.item ? ddo.cart.item : [] ),
		astBasket = [],
		/**
		 * returns the total price per item
		 */
		astItemPriBask = function ( i ) {
			return astCartItem[ i ].price.basePrice / astCartItem[ i ].quantity;
		};

	for ( var i = 0; i < astCartItem.length; i++ ) {
		astBasket.push( {
			sku: ( astCartItem[ i ].productInfo.sku ? astCartItem[ i ].productInfo.sku : '' ),
			name: ( astCartItem[ i ].productInfo.productName ? astCartItem[ i ].productInfo.productName : '' ),
			brand: ( astCartItem[ i ].productInfo.manufacturer ? astCartItem[ i ].productInfo.manufacturer : '' ),
			category: ( astCartItem[ i ].productInfo.subCategory2 ? astCartItem[ i ].productInfo.subCategory2 : '' ),
			price: ( astCartItem[ i ].price.basePrice && astCartItem[ i ].quantity && astItemPriBask ? astItemPriBask( i ) : '0' ),
			quantity: ( astCartItem[ i ].quantity ? astCartItem[ i ].quantity : '' )
		} );
	}

	_T.metarouter.track( 'Cart Viewed', {
		marketingCloudVisitorID: _aape.ECID,
		pageName: ( ddo.page.pageInfo.pageName ? ddo.page.pageInfo.pageName : '' ),
		pageType: ( ddo.page.pageInfo.pageType ? ddo.page.pageInfo.pageType : '' ),
		businessType: ( ddo.site.businessType ? ddo.site.businessType.toString().toLowerCase() : "b2c" ),
		pageFullCategory: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : ddo.page.category.subCategory1 ? ddo.page.category.subCategory1 : ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		primaryCategory: ( ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		subCategory2: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : '' ),
		cartId: ( ddo.cart.cartID ? ddo.cart.cartID : '' ),
		products: astBasket,
		total: ( ddo.cart.price.cartTotal ? ddo.cart.price.cartTotal : '' ),
		experienceType: ( remoteUtils.getDeviceType ? remoteUtils.getDeviceType() : '' ),
		mobileDevice: ( remoteUtils.getMobileDevice ? remoteUtils.getMobileDevice() : '' ),
		px: utilities.getPxScore()
	} );

}

/* cart remove event */
let cartRemove = function ( ddo ) {

	let eventIndex = ( typeof ( ddo.indexOfEventTrigger ) !== 'undefined' ? ddo.indexOfEventTrigger : "" ),
		ddoEvent = ( ddo.event[ eventIndex ] ? ddo.event[ eventIndex ].item[ 0 ] : [] );

	_T.metarouter.track( 'Product Removed', {
		marketingCloudVisitorID: _aape.ECID,
		pageName: ( ddo.page.pageInfo.pageName ? ddo.page.pageInfo.pageName : '' ),
		pageType: ( ddo.page.pageInfo.pageType ? ddo.page.pageInfo.pageType : '' ),
		businessType: ( ddo.site.businessType ? ddo.site.businessType.toString().toLowerCase() : "b2c" ),
		pageFullCategory: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : ddo.page.category.subCategory1 ? ddo.page.category.subCategory1 : ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		primaryCategory: ( ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		subCategory2: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : '' ),
		sku: ( ddoEvent.productInfo.sku ? ddoEvent.productInfo.sku : '' ),
		price: ( ddoEvent.price.basePrice ? ddoEvent.price.basePrice : '' ),
		quantity: ( ddoEvent.quantity ? ddoEvent.quantity : '' ),
		experienceType: ( remoteUtils.getDeviceType ? remoteUtils.getDeviceType() : '' ),
		mobileDevice: ( remoteUtils.getMobileDevice ? remoteUtils.getMobileDevice() : '' ),
		px: utilities.getPxScore()
	} );
}

/*purchase event for services */
let servicesConfirm = function ( ddo ) {

	run( ddo );

	_T.metarouter.track( 'home services appointment confirmation', {
		marketingCloudVisitorID: _aape.ECID,
		pageName: ( ddo.page.pageInfo.pageName ? ddo.page.pageInfo.pageName : '' ),
		pageType: ( ddo.page.pageInfo.pageType ? ddo.page.pageInfo.pageType : '' ),
		businessType: ( ddo.site.businessType ? ddo.site.businessType.toString().toLowerCase() : "b2c" ),
		pageFullCategory: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : ddo.page.category.subCategory1 ? ddo.page.category.subCategory1 : ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		primaryCategory: ( ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		subCategory2: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : '' ),
		leadId: ( ddo.page.form.universalLeadID ? ddo.page.form.universalLeadID : '' ),
		experienceType: ( remoteUtils.getDeviceType ? remoteUtils.getDeviceType() : '' ),
		mobileDevice: ( remoteUtils.getMobileDevice ? remoteUtils.getMobileDevice() : '' ),
		px: utilities.getPxScore()
	} )
}

/*purchase case for cart view page */
let purchase = function ( ddo ) {

	run( ddo );

	let astItemConf = ddo.transaction.item ? ddo.transaction.item : [],
		astCartConf = [],
		fulFilMent = ( utilities.getValuesFromObj( 'fulfillment.method', ddo.transaction.item ) ? utilities.getValuesFromObj( 'fulfillment.method', ddo.transaction.item ) : '' ),
		rmvo = false,
		rmvoPaint = false,
		rmvoPaver = false,
		rmvoCounterTops = false,
		rmvoWall = false,

		/**
		 * returns the total price per item
		 */
		astItemPriConf = function ( i ) {
			return astItemConf[ i ].price.basePrice / astItemConf[ i ].quantity;
		};

	for ( var i = 0; i < astItemConf.length; i++ ) {

		//flags for Roomvo items
		let department = ( astItemConf[ i ].productInfo.department ? astItemConf[ i ].productInfo.department : '' );

		if ( department ) {
			if ( department == '23' ) {
				rmvo = true;
			}
			else if ( department == '24' ) {
				rmvoPaint = true;
			}
			else if ( department == '28' ) {
				rmvoPaver = true;
			}
			else if ( department == '29' ) {
				rmvoCounterTops = true;
			}
			else if ( department == '59' ) {
				rmvoWall = true;
			}
		}

		astCartConf.push( {
			sku: ( astItemConf[ i ].productInfo.sku ? astItemConf[ i ].productInfo.sku : '' ),
			name: ( astItemConf[ i ].productInfo.productName ? astItemConf[ i ].productInfo.productName : '' ),
			brand: ( astItemConf[ i ].productInfo.manufacturer ? astItemConf[ i ].productInfo.manufacturer : '' ),
			category: ( astItemConf[ i ].productInfo.productType ? astItemConf[ i ].productInfo.productType : '' ),
			price: ( astItemConf[ i ].price.basePrice && astItemConf[ i ].quantity && astItemPriConf ? astItemPriConf( i ) : '0' ),
			quantity: ( astItemConf[ i ].quantity ? astItemConf[ i ].quantity : '' ),
			department: department,
			class: ( astItemConf[ i ].productInfo.class ? astItemConf[ i ].productInfo.class : '' ),
			subclass: ( astItemConf[ i ].productInfo.subclass ? astItemConf[ i ].productInfo.subclass : '' ),
			manufacturer: ( astItemConf[ i ].productInfo.manufacturer ? astItemConf[ i ].productInfo.manufacturer : '' )
		} );
	}

	_T.metarouter.track( 'Order Completed', {
		marketingCloudVisitorID: _aape.ECID,
		pageName: ( ddo.page.pageInfo.pageName ? ddo.page.pageInfo.pageName : '' ),
		pageType: ( ddo.page.pageInfo.pageType ? ddo.page.pageInfo.pageType : '' ),
		businessType: ( ddo.site.businessType ? ddo.site.businessType.toString().toLowerCase() : "b2c" ),
		pageFullCategory: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : ddo.page.category.subCategory1 ? ddo.page.category.subCategory1 : ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		primaryCategory: ( ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory : '' ),
		subCategory2: ( ddo.page.category.subCategory2 ? ddo.page.category.subCategory2 : '' ),
		order_id: ( ddo.transaction.transactionID ? ddo.transaction.transactionID : ddo.legacy && ddo.legacy.transactionID ? ddo.legacy.transactionID : '' ),
		cart_id: ( remoteUtils.getQueryParam( 'cartid' ) ? remoteUtils.getQueryParam( 'cartid' ).toLowerCase() : 'no cart id' ),
		products: astCartConf,
		rmvo: rmvo,
		rmvoPaint:rmvoPaint,
		rmvoPaver: rmvoPaver,
		rmvoCounterTops: rmvoCounterTops,
		rmvoWall: rmvoWall,
		revenue: ( ddo.transaction.price.basePrice ? ddo.transaction.price.basePrice : '0' ),
		total: ( ddo && ddo.transaction.price.transactionTotal ? ddo.transaction.price.transactionTotal : '0' ),
		shipping: ( ddo && ddo.transaction.price.shipping ? ddo.transaction.price.shipping : '0' ),
		tax: ( ddo && ddo.transaction.price.tax ? ddo.transaction.price.tax : '0' ),
		state: ( ddo.transaction.profile.address.stateProvince ? ddo.transaction.profile.address.stateProvince : '' ),
		zip: ( ddo.transaction.profile.address.postalCode ? ddo.transaction.profile.address.postalCode : '' ),
		fulfillment: fulFilMent,
		coupon: ( ( typeof ( ddo.transaction.item ) === 'object' ) && ddo.transaction.item[ 0 ] && ddo.transaction.item[ 0 ].price && ddo.transaction.item[ 0 ].price.discountCode ? ddo.transaction.item[ 0 ].price.discountCode : '' ),
		experienceType: ( remoteUtils.getDeviceType ? remoteUtils.getDeviceType() : '' ),
		mobileDevice: ( remoteUtils.getMobileDevice ? remoteUtils.getMobileDevice() : '' ),
		px: utilities.getPxScore()
	} );

	/*removal of movable ink cookie since the event has been sent */
	document.cookie = '_meta_movableInk_mi_cmp=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

}

export {
	appointmentMaker,
	search,
	plp,
	pip,
	run,
	atc,
	cart,
	cartRemove,
	piqImpression,
	purchase,
	servicesConfirm,
	registrationEvent,
	registrationProEvent
};
